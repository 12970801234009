* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  position: relative;
  width: 100%;
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
}

.card {
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  --shadow1: 1px 1px 5px black;
  --shadow2: 1px 1px 1px black;
  box-shadow: var(--shadow1);
  background: #f1e3e3;
  border-radius: 10%;
  transition: 1s;
}

.card:hover {
  --shadow1: 10px 10px 20px #0c181f;
  transform: scale(1.05);
  /* --shadow2: -5px -5px 20px #0c181f; */
}

h1 {
  font-family: "American Typewriter", sans-serif;
}

.link-wrapper {
  display: flex;
  justify-content: space-evenly;
}

.link-wrapper a:hover {
  border-radius: 10%;
  box-shadow: 1px 1px grey, -1px -1px grey;
}

#stacks {
  color: white;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#stacks-header {
  margin: 2rem 0;
  color: white;
}

.stack-title {
  text-decoration: underline;
}

.stack-list {
  text-align: center;
}

.stack-list li {
  list-style-type: none;
  text-align: center;
}

.word-cloud--container li {
  list-style-type: none;
}

#footer-right {
  position: absolute;
  bottom: 0;
  right: 0.5rem;
  padding: 0.25rem;
  border: 50%;
  font-size: 0.5rem;
  border: 1px solid black;
  background-color: black;
  color: white;
}

.footer-link {
  color: white;
}